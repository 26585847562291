import "%modules%/header/header";
import "%modules%/main_sl/main_sl";
import "%modules%/main_bl/main_bl";
import "%modules%/main_form/main_form";
import "%modules%/step/step";
import "%modules%/main_pref/main_pref";
import "%modules%/main_wrk/main_wrk";
import "%modules%/main_ser/main_ser";
import "%modules%/main_rev/main_rev";
import "%modules%/main_map/main_map";
import "%modules%/main_ques/main_ques";
import "%modules%/footer/footer";